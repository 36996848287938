.main-layout {
    margin-top: 100px;
    margin-left: 250px;
    transition: all 0.3s ease-in-out;
    padding: 0 10px;
    
    @media (max-width: 800px) {
        margin-top: 0px;
        margin-left: 0;
    }
}