.appbar-container {
    position: fixed;
    top: 0;
    left: 250px;
    z-index: 1;
    width: calc(100% - 250px);
    height: 80px;
    background: #1a1537;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 27px;
    padding-right: 10px;
    gap: 20px;
    transition: all 0.3s ease-in-out;
    z-index: 1000;
    backdrop-filter: blur(4px);

    @media (max-width: 800px) {
        left: 0;
        width: 100%;
        height: 70px;
        padding-left: 5px;
    }

    .page-title {
        @media (max-width: 800px) {
            display: none;
        }

        .title {
            color: white;
            font-size: 22px;
            font-weight: 700;

            @media (max-width: 800px) {
                color: #3E8BFF;
            }
        }

        .description {
            color: #BAB9C3;
            font-size: 14px;
        }
    }

    .logo {
        display: none;
        min-width: 30px;

        @media (max-width: 800px) {
            display: block;
        }
    }

    .mobile-menu {
        display: none;

        @media (max-width: 800px) {
            display: block;
        }

        .menu-icon {
            color: white;
        }
    }
}

.mobile-page-title {
    display: none;
    text-align: center;
    padding: 20px 10px;

    @media (max-width: 800px) {
        display: block;
        margin-top: 70px;

        .title {
            font-size: 22px;
            font-weight: 700;
            color: white;
        }

        .description {
            color: #BAB9C3;
            font-size: 14px;
        }
    }
}

.MuiPopover-paper.MuiMenu-paper {
    background: #241f41ce;
    border: 1px solid #2d2753;
    border-radius: 15px;
    backdrop-filter: blur(10px);

    li {
        justify-content: center;
    }

    a {
        text-transform: uppercase;
        color: white;
    }

    .mobile-logo img {
        width: 140px !important;
    }
}