.home-box {
    padding-top: 30px;
    padding-bottom: 60px;

    @media (max-width: 800px) {
        padding-top: 0;
    }

    .card {
        max-width: 620px;
        width: 100%;
        border-radius: 25px;
        border: 1px solid #ffffff33;
        background-color: #110d3070;
        padding: 30px;
        overflow: hidden;
        z-index: 1;
        position: relative;
        color: white;
        margin: auto;

        @media (max-width: 800px) {
            padding-bottom: 15px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/images/glass.png);
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.3;
            z-index: -1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 60%;
            aspect-ratio: 1/1;
            top: calc(50% - 30%);
            left: calc(50% - 30%);
            border-radius: 500px;
            opacity: 0.5;
            background: linear-gradient(90deg, #1CABFC 0%, #6233E7 100%);
            filter: blur(80px);
            z-index: -2;

            @media (max-width: 800px) {
                width: 70%;
                opacity: 0.7;
            }
        }

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ffffff33;
            padding-bottom: 10px;

            .amount-label {
                color: #FFFfffa0;
                font-size: 16px;

                @media (max-width: 800px) {
                    font-size: 12px;
                }
            }

            .amount {
                font-size: 25px;
                font-weight: 700;

                @media (max-width: 800px) {
                    font-size: 20px;
                }
            }

            .account-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                border-radius: 5px;
                background: linear-gradient(180deg, #100B2D 0%, rgba(16, 11, 45, 0.30) 100%);
                padding: 5px 10px;

                .address {
                    font-size: 12px;
                    color: #FFFfffa0;
                }

                .avatar {
                    width: 28px;
                    height: 28px;
                    background: linear-gradient(90deg, #01C6F1 0%, #3B84F7 100%);
                }
            }
        }

        .card-body {
            padding-top: 20px;
            padding-bottom: 20px;

            @media (max-width: 800px) {
                padding-top: 10px;
            }

            .input-group {
                overflow: hidden;
                position: relative;

                .MuiTextField-root {
                    width: 100%;
                    background: #1a153766;
                    border-radius: 10px;

                    input {
                        color: white;
                        font-size: 14px;
                        height: 26px;
                        padding-left: 20px;
                        padding-right: 100px;
                    }
                }

                fieldset {
                    border-radius: 10px;
                    border: 1px solid #ffffff33;
                }

                .btn-max {
                    border-radius: 5px;
                    background: #01C6F11a;
                    color: #01C6F1;
                    font-size: 12px;
                    font-weight: 500;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }

            .stake-option {
                border-radius: 25px;
                border: 1px solid #ffffff33;
                background: #ffffff1a;
                padding: 18px 38px;
                margin-top: 18px;
                cursor: pointer;
                transition: all 0.2s ease;
                opacity: 0.7;
                user-select: none;

                @media (max-width: 800px) {
                    opacity: 1;
                    border-radius: 12px;
                    padding: 10px 20px;
                    margin-top: 10px;
                }

                &:hover {
                    opacity: 1;
                }

                &.selected {
                    background-image: url(../../assets/images/card-select-bg.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    color: black;
                    opacity: 1;
                }

                .column-title {
                    font-size: 12px;
                    text-transform: uppercase;
                    opacity: 0.7;
                    font-weight: 500;

                    @media (max-width: 800px) {
                        font-size: 10px;
                    }
                }

                .column-value {
                    font-size: 20px;
                    font-weight: 700;

                    @media (max-width: 800px) {
                        font-size: 14px;
                    }
                }
            }
        }

        .btn-stake {
            width: 100%;
            margin-top: 30px;
            border-radius: 10px;
            background: linear-gradient(90deg, #01C6F1 0%, #3B84F7 100%);
            color: white;
            font-size: 16px;
            font-weight: 700;
            text-transform: none;
            padding: 11px;

            @media (max-width: 800px) {
                margin-top: 20px;
            }

            &::before {
                content: '';
                position: absolute;
                width: 85%;
                height: 60%;
                border-radius: 10px;
                background: linear-gradient(90deg, #01C6F1 0%, #3B84F7 100%);
                filter: blur(10px);
                bottom: -20%;
                opacity: 0.4;
                z-index: -1;
            }
        }
    }
}