.stakelog-box {
    padding-top: 30px;
    padding-bottom: 60px;

    @media (max-width: 800px) {
        padding-top: 0;
    }

    .card {
        max-width: 1000px;
        width: 95%;
        border-radius: 25px;
        border: 1px solid #ffffff33;
        background-color: #110d3070;
        padding: 30px;
        overflow: hidden;
        z-index: 1;
        position: relative;
        color: white;
        margin: auto;

        @media (max-width: 800px) {
            padding: 20px;
            padding-bottom: 15px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/images/glass.png);
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.3;
            z-index: -1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 60%;
            aspect-ratio: 1/1;
            top: calc(50% - 30%);
            left: calc(50% - 30%);
            border-radius: 500px;
            opacity: 0.5;
            background: linear-gradient(90deg, #1CABFC 0%, #6233E7 100%);
            filter: blur(80px);
            z-index: -2;

            @media (max-width: 800px) {
                width: 70%;
                opacity: 0.7;
            }
        }

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ffffff33;
            padding-bottom: 10px;

            .header-title {
                font-size: 25px;
                font-weight: 700;

                @media (max-width: 800px) {
                    font-size: 20px;
                }
            }

            .account-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                border-radius: 5px;
                background: linear-gradient(180deg, #100B2D 0%, rgba(16, 11, 45, 0.30) 100%);
                padding: 5px 10px;

                .address {
                    font-size: 12px;
                    color: #FFFfffa0;
                    font-weight: 500;
                }
            }
        }

        .card-body {
            padding-top: 10px;
            padding-bottom: 10px;

            @media (max-width: 800px) {
                padding-top: 10px;
            }

            .stake-option {
                border-radius: 25px;
                border: 1px solid #ffffff33;
                background: #ffffff1a;
                padding: 18px 30px;
                margin-top: 18px;
                cursor: pointer;
                transition: all 0.2s ease;
                user-select: none;
                row-gap: 10px;

                @media (max-width: 800px) {
                    opacity: 1;
                    border-radius: 12px;
                    padding: 10px 20px;
                    margin-top: 10px;
                    row-gap: 5px;
                }

                .column-title {
                    font-size: 12px;
                    font-weight: 600;
                    color: #01C6F1;
                    opacity: 0.8;

                    @media (max-width: 800px) {
                        font-size: 10px;
                    }

                    .rewarded {
                        font-size: 12px !important;

                        @media (max-width: 800px) {
                            font-size: 10px;
                        }
                    }
                }

                .column-value {
                    font-size: 16px;
                    opacity: 0.8;
                    font-weight: 600;

                    @media (max-width: 800px) {
                        font-size: 10px;
                    }
                }
            }

            .rewarded {
                color: #edfc1c;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 600;

                @media (max-width: 800px) {
                    font-size: 12px;
                }
            }

            .no-data {
                font-size: 14px;
                font-weight: 500;
                opacity: 0.7;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
}