body * {
    font-family: "montserrat" !important;

    a {
        text-decoration: none;
        color: inherit;
    }
}

.MuiContainer-root {
    max-width: 1366px !important;
}

.w-100 {
    width: 100%;
}

.m-auto {
    margin: auto;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px; // Add this line to make the scrollbar thin for horizontal scrollbar
}

::-webkit-scrollbar-thumb {
    background-color: #ffffff30;
    border-radius: 16px;
}

::-webkit-scrollbar-track {
    background: #ffffff10;
}

.bg-toast {
    border-radius: 8px !important;
    border: 1px solid rgba(255, 255, 255, 0.09) !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%) !important;
    -webkit-backdrop-filter: blur(40px) !important;
    backdrop-filter: blur(40px) !important;
}

.coming-soon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    font-size: 70px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    @media screen and (max-width: 800px) {
        font-size: 30px;
    }

    .btn-look {
        width: 100%;
        margin: auto;
        margin-top: 30px;
        border-radius: 10px;
        background: linear-gradient(90deg, #01C6F1 0%, #3B84F7 100%);
        color: white;
        font-size: 16px;
        font-weight: 700;
        text-transform: none;
        padding: 11px;

        @media (max-width: 800px) {
            margin-top: 20px;
            width: 80%;
        }

        &::before {
            content: '';
            position: absolute;
            width: 85%;
            height: 60%;
            border-radius: 10px;
            background: linear-gradient(90deg, #01C6F1 0%, #3B84F7 100%);
            filter: blur(10px);
            bottom: -20%;
            opacity: 0.4;
            z-index: -1;
        }
    }
}