.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 250px;
    height: 100vh;
    background: #1a1537;
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    @media (max-width: 800px) {
        width: 0;
    }

    .logo {
        padding: 16px 0;
    }

    .menu-wrapper {
        margin-top: 25px;
        padding: 0;

        .menu-item {
            padding: 8px 10px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            margin-bottom: 10px;

            .menu-icon {
                width: 26px;
                height: 26px;
                min-width: 0;
                margin-left: 17px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .menu-text {
                background: #767387;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                * {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 16px;
                }
            }

            &.active {
                width: calc(100% - 15px);
                background: linear-gradient(90deg, #01EAE930 0%, #6988FF30 100%);

                &::before {
                    content: '';
                    position: absolute;
                    top: calc(50% - 10px);
                    right: -10px;
                    width: 10px;
                    height: 20px;
                    background: #6988FF30;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 15px);
                    right: -15px;
                    width: 7px;
                    height: 30px;
                    border-radius: 3px;
                    background: linear-gradient(90deg, #01EAE9 0%, #6988FF 100%);
                }

                .menu-text {
                    background: linear-gradient(90deg, #01EAE9 0%, #6988FF 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}